import React from 'react';

import SectionOverviewIntro from '../../../../Section/SectionOverviewIntro';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  name: string;
  rugCount: number;
  searchForm: ReactNode;
  titleHeaderPortrait: ReactNode;
};

const CollectionOverviewSectionIntro = ({ appInfo, name, rugCount, searchForm, titleHeaderPortrait }: Props) => {
  return (
    <SectionOverviewIntro appInfo={appInfo} titleHeaderPortrait={titleHeaderPortrait}>
      {rugCount} rugs in {name}
      <br /> Refine your search in this collection:
      <br />
      {searchForm}
    </SectionOverviewIntro>
  );
};

export default CollectionOverviewSectionIntro;
