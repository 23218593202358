import React from 'react';
import { graphql } from 'gatsby';

import CollectionOverviewContainer from '../containers/Collection/CollectionOverview';
import LayoutContainer from '../containers/Layout';
import theme from '../utils/styling/theme';

// Types
import { CollectionOverviewPageTemplateQuery } from './__generated__/CollectionOverviewPageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query CollectionOverviewPageTemplateQuery($where: CMS_JSON!) {
    cms {
      collectionpages(where: $where) {
        ...CollectionOverviewContainerFragment
      }
    }
  }
`;

type Props = {
  data: CollectionOverviewPageTemplateQuery;
} & PageRendererProps;

const CollectionOverviewPageTemplate = ({ data }: Props) => {
  if (!data.cms.collectionpages || data.cms.collectionpages.length !== 1) {
    throw Error('Something went wrong');
  }

  const [collectionPage] = data.cms.collectionpages;

  if (!collectionPage) {
    throw Error('Something went wrong');
  }

  return (
    <LayoutContainer backgroundColor={theme.color.white} logoColor={theme.color.brand}>
      <CollectionOverviewContainer collectionPage={collectionPage} />
    </LayoutContainer>
  );
};

export default CollectionOverviewPageTemplate;
