import idx from 'idx';
import { graphql } from 'gatsby';

import buildCollectionOverviewTitle from './buildCollectionOverviewTitle';
import buildCollectionOverviewUrl from '../url/buildCollectionOverviewUrl';
import getSeoPicture from './getSeoPicture';

// Types
import { BuildCollectionOverviewSeoPropsFragment } from './__generated__/BuildCollectionOverviewSeoPropsFragment';

export const fragment = graphql`
  fragment BuildCollectionOverviewSeoPropsFragment on CMS_Collectionpage {
    slug
    title
    collection_jk {
      description
      id
      cover {
        ...SeoMetaPictureFragment
      }
    }
  }
`;

const buildCollectionOverviewSeoProps = (collectionPage: BuildCollectionOverviewSeoPropsFragment) => {
  const cover = idx(collectionPage, _ => _.collection_jk.cover) || null;
  const description = idx(collectionPage, _ => _.collection_jk.description);
  const slug = collectionPage.slug;
  const title = buildCollectionOverviewTitle(collectionPage.title);

  const canonicalUrl = buildCollectionOverviewUrl(slug);
  const image = getSeoPicture({ alt: title, picture: cover });

  return {
    canonicalUrl,
    description,
    image,
    openGraphDescription: description,
    openGraphTitle: title,
    title,
  };
};

export default buildCollectionOverviewSeoProps;
