import React from 'react';

import buildRugColor from '../rug/buildRugColor';
import buildRugMaterial from '../rug/buildRugMaterial';

// Types
import { ChangeEvent, FormEvent } from 'react';
import { SortableRug } from '../../containers/Collection/CollectionOverview/utils';

const normalizeString = (term: string | null) => {
  return (term || '').toLowerCase().trim();
};

type FilterRugInput = {
  rug: SortableRug;
  term: string;
};

const filterRug = ({ rug, term }: FilterRugInput) => {
  if (term.length > 0 && !rug.isPrimary) {
    return false;
  }

  const terms = normalizeString(term).split(' ');

  const color = normalizeString(buildRugColor(rug));
  const extra = normalizeString(rug.extra);
  const material = normalizeString(buildRugMaterial(rug));
  const name = normalizeString(rug.name);

  const searchString = `${color} ${extra} ${material} ${name}`.trim();

  if (terms.every(normalizedTerm => searchString.includes(normalizedTerm))) {
    return true;
  }

  return false;
};

const useCollectionOverviewSearch = (rugs: SortableRug[]) => {
  const [filteredRugs, setFilteredRugs] = React.useState<SortableRug[]>(rugs);
  const [nextTerm, setNextTerm] = React.useState<string>('');
  const [term, setTerm] = React.useState<string>('');

  const handleNextTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNextTerm(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!nextTerm.trim()) {
      setFilteredRugs(rugs);
    }

    const nextFilteredRugs = rugs.filter(rug => filterRug({ rug, term: nextTerm }));

    setFilteredRugs(nextFilteredRugs);
    setTerm(nextTerm);
  };

  return { filteredRugs, onSubmit: handleSubmit, onNextTermChange: handleNextTermChange, nextTerm, term };
};

export default useCollectionOverviewSearch;
