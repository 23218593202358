import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CollectionOverview from '../../../components/Collection/CollectionOverview';
import CollectionOverviewPageBreadcrumb from '../../../utils/seo/CollectionOverviewPageBreadcrumb';
import SEOContainer from '../../Layout/SEO';
import SearchForm from '../../../components/Search/SearchItems/SearchForm';
import SearchSectionRugsRugContainer from '../../Search/SearchSections/SearchSectionRugs/SearchSectionRugsRug';
import SectionCarouselCollectionsContainer from '../../Section/SectionCarouselCollections';
import SectionFooterShowroomsDefaultContainer from '../../Section/SectionFooterShowroomsDefault';
import SectionOverviewIntroAppInfoContainer from '../../Section/SectionOverviewIntro/SectionOverviewIntroAppInfo';
import TitleHeaderPortraitContainer from '../../TitleHeader/TitleHeaderPortrait';
import buildCollectionOverviewSeoProps from '../../../utils/seo/buildCollectionOverviewSeoProps';
import buildCollectionPageUrl from '../../../utils/url/buildCollectionPageUrl';
import theme from '../../../utils/styling/theme';
import useCollectionOverviewSearch from '../../../utils/hooks/useCollectionOverviewSearch';
import useHeaderContext from '../../../utils/context/HeaderContext';
import { shouldScrollTo, sortNewRugsToFront } from './utils';

// Types
import { CollectionOverviewContainerFragment } from './__generated__/CollectionOverviewContainerFragment';

type Props = {
  collectionPage: CollectionOverviewContainerFragment;
};

export const fragment = graphql`
  fragment CollectionOverviewContainerFragment on CMS_Collectionpage {
    ...BuildCollectionOverviewSeoPropsFragment
    ...CollectionOverviewPageBreadcrumbFragment
    id
    slug
    title
    collection_jk {
      id
      name
      rugs(sort: "name") {
        ...SearchSectionRugsRugContainerFragment
        color
        id
        isPrimary
        isMotherpiece
        isVisibleOnWebsite
        material
        postExtra
        preExtra
        collection_jk {
          collectionPage {
            slug
          }
        }
      }
    }
  }
`;

const CollectionOverviewContainer = ({ collectionPage }: Props) => {
  const seoProps = buildCollectionOverviewSeoProps(collectionPage);

  const collectionName = idx(collectionPage, _ => _.collection_jk.name);
  const collectionSlug = idx(collectionPage, _ => _.slug);
  const rugs = (idx(collectionPage, _ => _.collection_jk.rugs) || []).filter(rug => rug.isPrimary);

  const sortedRugs = sortNewRugsToFront(rugs);

  const { filteredRugs, nextTerm, onSubmit, onNextTermChange, term } = useCollectionOverviewSearch(sortedRugs);

  const { setHeader } = useHeaderContext();

  React.useEffect(() => {
    if (!!collectionName && !!collectionSlug) {
      const nextBackButtonTitle = 'Back to collection page';
      const nextBackButtonUrl = buildCollectionPageUrl(collectionSlug);

      setHeader({
        backButtonTitle: nextBackButtonTitle,
        backButtonUrl: nextBackButtonUrl,
        title: 'Collection Overview',
        subtitle: collectionName,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, collectionSlug, term]);

  if (!collectionName || !collectionSlug) {
    return null;
  }

  const appInfo = <SectionOverviewIntroAppInfoContainer />;
  const appInfoPortrait = <SectionOverviewIntroAppInfoContainer isPortraitVariant={true} />;
  const carousel = <SectionCarouselCollectionsContainer isHiddenOnMobile={false} />;
  const footer = <SectionFooterShowroomsDefaultContainer backgroundColor={theme.color.white} textColor={theme.color.black} />;
  const searchForm = <SearchForm onChange={onNextTermChange} onSubmit={onSubmit} term={nextTerm} />;
  const titleHeaderPortrait = <TitleHeaderPortraitContainer />;

  const mappedRugs = filteredRugs.map((rug, index) => (
    <SearchSectionRugsRugContainer
      isCritical={index <= 4}
      key={rug.id}
      rug={rug}
      shouldScrollTo={shouldScrollTo(rug)}
      slug={collectionPage.slug}
    />
  ));

  return (
    <>
      {/* SEO */}
      <CollectionOverviewPageBreadcrumb collectionPage={collectionPage} />
      <SEOContainer {...seoProps} />

      <CollectionOverview
        appInfo={appInfo}
        appInfoPortrait={appInfoPortrait}
        carousel={carousel}
        footer={footer}
        name={collectionName}
        rugs={mappedRugs}
        searchForm={searchForm}
        term={term}
        title={seoProps.title}
        titleHeaderPortrait={titleHeaderPortrait}
      />
    </>
  );
};

export default CollectionOverviewContainer;
