import React from 'react';

import CollectionOverviewSectionIntro from './CollectionOverviewSections/CollectionOverviewSectionIntro';
import Heading from '../../Heading';
import SearchSectionRugs from '../../Search/SearchSections/SearchSectionRugs';
import Section from '../../Heading/Section';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  appInfoPortrait: ReactNode;
  carousel: ReactNode;
  footer: ReactNode;
  name: string;
  rugs: ReactNode;
  searchForm: ReactNode;
  term: string;
  title?: string;
  titleHeaderPortrait: ReactNode;
};

const CollectionOverview = ({
  appInfo,
  appInfoPortrait,
  carousel,
  footer,
  name,
  rugs,
  searchForm,
  term,
  title,
  titleHeaderPortrait,
}: Props) => {
  const rugCount = React.Children.count(rugs);

  return (
    <>
      {!!title && <Heading isVisuallyHidden={true}>{title}</Heading>}

      <Section hasNoTag={true}>
        <Heading isVisuallyHidden={true}>Rugs</Heading>

        <CollectionOverviewSectionIntro
          appInfo={appInfo}
          name={name}
          titleHeaderPortrait={titleHeaderPortrait}
          rugCount={rugCount}
          searchForm={searchForm}
        />

        <SearchSectionRugs isLoading={false} resultsCount={rugCount} rugs={rugs} term={term} shouldRenderAllIfNoTerm={true} />

        {appInfoPortrait}

        {carousel}

        {footer}
      </Section>
    </>
  );
};

export default CollectionOverview;
